
import React, { useEffect, useContext } from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import Layout from '@components/layout';
import SEO from '@components/seo';
import EdmusContext from '@components/application.provider';

export const query = graphql`
{
  prismic {
      allNewCellars {
        edges {
          node {
            introImage
            title
            introduction
            image2
            imageArchitects
            legendArchitects
            introductionArchitects
            commentsArchitects
            architectsQuestionsAnswers
            _meta {
              lang
            }
          }
        }
      }
    }
  }
`;

const NewCellar = (props: any) => {
  const { language } = useContext(EdmusContext);
  const doc = props.data.prismic.allNewCellars.edges.find(
    (docs: any) => docs.node._meta.lang.slice(0, 2) === language
  );
  if (!doc) return null;
  return (

    <Layout>
      <div className='new-cellar'>
        <SEO title={doc.node.title} />

        {doc.node.introImage &&
          <img className='img-bar lazyload img-responsive' data-src={doc.node.introImage.url} alt={doc.node.introImage.alt} />
        }

        <div className='page-title space-top-20'>{doc.node.title}</div>

        <div className='container sub-container space-top-20 space-bottom-20'>
          {RichText.render(doc.node.introduction)}
        </div>

        {doc.node.image2 &&
          <img className='img-bar lazyload img-responsive' data-src={doc.node.image2.url} alt={doc.node.image2.alt} />
        }

        <div className='container sub-container space-top-20 space-bottom-20'>
          {doc.node.introductionArchitects}
        </div>

        <div className='container'>
          <div className="columns">
            <div className="col-7 col-lg-12">
              {doc.node.imageArchitects &&
                <img className="col-12" src={doc.node.imageArchitects.url} alt={doc.node.imageArchitects.alt} />
              }
            </div>
            <div className="col-4 col-lg-10 text-over-image flex-centered">
              {doc.node.legendArchitects}
            </div>
          </div>
        </div>

        <div className='container sub-container comments-architects'>
          {RichText.render(doc.node.commentsArchitects)}
        </div>

        <div className='container sub-container architects-questions-answers'>
          {RichText.render(doc.node.architectsQuestionsAnswers)}
        </div>
      </div>
    </Layout>
  );
};

export default NewCellar;
